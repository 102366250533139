import { LLMChat } from "../../../../../types/LLMChat";
import { clipString } from "../../../../../utils/clip-string";
import { groupChatsByDate } from "../../../../../utils/group-chats";

import styles from "./index.module.css";

type Props = {
  selectedChatId?: number;
  chats: LLMChat[];
  chatClickHandler: (id: number) => void;
};

export const ChatsList = ({
  selectedChatId,
  chats,
  chatClickHandler,
}: Props) => {
  const groupedChats = groupChatsByDate(chats);
  const todayChats = groupedChats.today;
  const yesterdayChats = groupedChats.yesterday;
  const lastWeekChats = groupedChats.lastWeek;
  const historyChats = groupedChats.history;
  return (
    <div className={styles.list}>
      {!!todayChats.length && (
        <div className={styles.dateGroup}>
          <p className={styles.dateGroupTitle}>Idag</p>
          <div className={styles.chatsList}>
            {todayChats.map((chat) => {
              const clippedName = clipString(chat.name);
              return (
                <div
                  key={chat.id}
                  className={`${styles.chatItem} ${
                    selectedChatId === chat.id && styles.selectedChatItem
                  }`}
                  onClick={() => {
                    chatClickHandler(chat.id);
                  }}
                >
                  {clippedName}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!!yesterdayChats.length && (
        <div className={styles.dateGroup}>
          <p className={styles.dateGroupTitle}>Igår</p>
          <div className={styles.chatsList}>
            {yesterdayChats.map((chat) => {
              const clippedName = clipString(chat.name);
              return (
                <div
                  key={chat.id}
                  className={`${styles.chatItem} ${
                    selectedChatId === chat.id && styles.selectedChatItem
                  }`}
                  onClick={() => {
                    chatClickHandler(chat.id);
                  }}
                >
                  {clippedName}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!!lastWeekChats.length && (
        <div className={styles.dateGroup}>
          <p className={styles.dateGroupTitle}>Senaste veckan</p>
          <div className={styles.chatsList}>
            {lastWeekChats.map((chat) => {
              const clippedName = clipString(chat.name);
              return (
                <div
                  key={chat.id}
                  className={`${styles.chatItem} ${
                    selectedChatId === chat.id && styles.selectedChatItem
                  }`}
                  onClick={() => {
                    chatClickHandler(chat.id);
                  }}
                >
                  {clippedName}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!!historyChats.length && (
        <div className={styles.dateGroup}>
          <p className={styles.dateGroupTitle}>Historia</p>
          <div className={styles.chatsList}>
            {historyChats.map((chat) => {
              const clippedName = clipString(chat.name);
              return (
                <div
                  key={chat.id}
                  className={`${styles.chatItem} ${
                    selectedChatId === chat.id && styles.selectedChatItem
                  }`}
                  onClick={() => {
                    chatClickHandler(chat.id);
                  }}
                >
                  {clippedName}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
