import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { useEffect } from "react";
import { getAuthenticationCode } from "../../utils/local-storage";
export const WelcomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const storedCode = getAuthenticationCode();
    if (storedCode) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <img src="/assets/logo-icon.png" alt="logo" width="100" />
          <h2>
            Clouseau: The World's First AI Service for Access Control Data
          </h2>
        </div>
        <div className={styles.buttonsContainer}>
          <Link to="mailto:info@clouseau.se" className={styles.link}>
            More Info
          </Link>
          <Link className={styles.link} to="/auth">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};
