import { useEffect } from "react";
import { PromptInput } from "../../components/common/PromptInput";
import styles from "./index.module.css";

type Props = {
  disabled: boolean;
  clearSelectedChatIdHandler: () => void;
  createChatWithMessageHandler: (message: string) => void;
};

export const HomePage = ({
  clearSelectedChatIdHandler,
  disabled,
  createChatWithMessageHandler,
}: Props) => {
  useEffect(() => {
    clearSelectedChatIdHandler();
  }, [clearSelectedChatIdHandler]);

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <p className={styles.header}>Prata med mig!</p>
        <div className={styles.inputContainer}>
          <PromptInput
            disabled={disabled}
            sendMessageHandler={createChatWithMessageHandler}
          />
        </div>
      </div>
    </div>
  );
};
