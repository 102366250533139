import { ClipLoader } from "react-spinners";
import styles from "./index.module.css";

type Props = {
  show: boolean;
};

export const FullPageLoader = ({ show }: Props) => {
  return show ? (
    <div className={styles.overlay}>
      <div className={styles.spinner}>
        <ClipLoader color="#ffffff" size={60} />
      </div>
    </div>
  ) : (
    <></>
  );
};
