import { useState } from "react";
import styles from "./index.module.css";
import { ListIcon } from "../../../icons/list-icon";
import { CreateChatButton } from "./CreateChatButton";
import { CloseIcon } from "../../../icons/close-icon";
import { LLMChat } from "../../../../types/LLMChat";
import { ChatsList } from "./ChatsList";
import { useNavigate } from "react-router-dom";

type Props = {
  selectedChatId?: number;
  chats: LLMChat[];
  createChatHandler: () => void;
};

export const Navbar = ({ chats, selectedChatId, createChatHandler }: Props) => {
  const [opened, setOpened] = useState(false);

  const navigate = useNavigate();

  const chatClickHandler = (id: number) => {
    navigate(`/chat/${id}`);
  };

  const openNavbar = () => {
    setOpened(true);
  };

  const closeNavbar = () => {
    setOpened(false);
  };

  return (
    <nav className={styles.container}>
      {opened ? (
        <>
          <div className={styles.mobileFiller}></div>
          <div className={styles.opened}>
            <div className={styles.navbarContainer}>
              <div className={styles.head}>
                <div className={styles.closeIcon} onClick={closeNavbar}>
                  <CloseIcon />
                </div>
                <CreateChatButton createChatHandler={createChatHandler} />
              </div>
              <ChatsList
                chats={chats}
                chatClickHandler={chatClickHandler}
                selectedChatId={selectedChatId}
              />
            </div>
            <div className={styles.backdrop} onClick={closeNavbar} />
          </div>
        </>
      ) : (
        <div className={styles.closed}>
          <div className={styles.listIcon} onClick={openNavbar}>
            <ListIcon />
          </div>
        </div>
      )}
    </nav>
  );
};
