export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0,0,256,256"
      width="10px"
      height="10px"
    >
      <g
        fill="#4f4f4f"
        fill-rule="evenodd"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M11,2v9h-9v2h9v9h2v-9h9v-2h-9v-9z"></path>
        </g>
      </g>
    </svg>
  );
};
