import { PropsWithChildren, useEffect } from "react";

import styles from "./index.module.css";
import { Navbar } from "./Navbar";
import { Header } from "./Header";
import { LLMChat } from "../../../types/LLMChat";
import { FullPageLoader } from "../FullPageLoader";
import { loadLLMChatsReq } from "../../../api/requests";
import { AxiosError } from "axios";
import { toastError } from "../../../utils/toastError";
import { getAuthenticationCode } from "../../../utils/local-storage";

type Props = {
  showLoading: boolean;
  chats: LLMChat[];
  selectedChatId?: number;
  createChatHandler: () => void;
  setChatsHandler: (chats: LLMChat[]) => void;
  setPageLoadingHandler: (loading: boolean) => void;
  navigateToWelcome: () => void;
};

export const Layout = ({
  chats,
  showLoading,
  selectedChatId,
  createChatHandler,
  setChatsHandler,
  setPageLoadingHandler,
  navigateToWelcome,
  children,
}: PropsWithChildren<Props>) => {
  const authCode = getAuthenticationCode();

  const loadChatsHandler = async () => {
    try {
      const { data } = await loadLLMChatsReq({ code: authCode });
      setChatsHandler(data);
    } catch (error) {
      const axiosError = error as AxiosError<{
        message: string;
        status: number;
      }>;
      const errorMessage =
        axiosError.response?.data.message || "Error loading chats!";
      const errorStatus = axiosError.response?.status;
      if (errorStatus === 401) {
        navigateToWelcome();
      } else {
        toastError(errorMessage);
      }
    }
    setPageLoadingHandler(false);
  };

  useEffect(() => {
    if (authCode) {
      loadChatsHandler();
    } else {
      navigateToWelcome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);

  return (
    <div className={styles.page}>
      <FullPageLoader show={showLoading} />
      <Navbar
        chats={chats}
        selectedChatId={selectedChatId}
        createChatHandler={createChatHandler}
      />
      <div className={styles.pageContent}>
        <Header />
        {children}
      </div>
    </div>
  );
};
