import { LLMChat } from "../types/LLMChat";
import { LLMChatSendMessageResponse } from "../types/LLMChatSendMessageResponse";
import { AuthenticateLLMChatInput } from "../types/authenticate-chat-input";
import { CreateLLMChatInput } from "../types/create-chat-input";
import { deleteLLMChatInput } from "../types/delete-llm-chat-input";
import { loadLLMChatInput } from "../types/load-llm-chat-input";
import { loadLLMChatsInput } from "../types/load-llm-chats-input";
import { sendLLMChatMessageInput } from "../types/send-llm-chat-message-input";
import axiosClient from "./axiosClient";

export const authenticateLLMChatsReq = async (
  data: AuthenticateLLMChatInput
) => {
  return axiosClient.post<boolean>(`/llm-chats/auth`, data);
};

export const createLLMChatsReq = async (data: CreateLLMChatInput) => {
  return axiosClient.post<LLMChat>(`/llm-chats/create`, data);
};

export const loadLLMChatsReq = async (data: loadLLMChatsInput) => {
  return axiosClient.post<LLMChat[]>(`/llm-chats/load`, data);
};

export const loadLLMChatReq = async (id: number, data: loadLLMChatInput) => {
  return axiosClient.post<LLMChat>(`/llm-chats/load/${id}`, data);
};

export const sendLLMChatMessageReq = async (data: sendLLMChatMessageInput) => {
  return axiosClient.post<LLMChatSendMessageResponse>(
    `/llm-chats/send-message`,
    data
  );
};

export const deleteLLMChatsReq = async (
  id: number,
  data: deleteLLMChatInput
) => {
  return axiosClient.post<void>(`/llm-chats/delete/${id}`, data);
};
