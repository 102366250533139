import "react-toastify/dist/ReactToastify.css";

import { Bounce, ToastContainer } from "react-toastify";
import { Layout } from "../components/common/Layout";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useState } from "react";
import { LLMChat } from "../types/LLMChat";
import {
  clearAuthenticationCode,
  getAuthenticationCode,
} from "../utils/local-storage";
import { createLLMChatsReq } from "../api/requests";
import { AxiosError } from "axios";
import { toastError } from "../utils/toastError";
import { ChatPage } from "./Chat";
import { HomePage } from "./Home";
import { Authentication } from "./Authentication";
import { WelcomePage } from "./Welcome";
import { RedirectToHomePage } from "./RedirectToHome";

export const IndexPage = () => {
  const [chats, setChats] = useState<LLMChat[]>();
  const [selectedChatId, setSelectedChatId] = useState<number>();
  const [pageLoading, setPageLoading] = useState(true);

  const navigate = useNavigate();

  const authCode = getAuthenticationCode();

  const setChatsHandler = (chats: LLMChat[]) => {
    setChats(chats);
  };

  const setPageLoadingHandler = (loading: boolean) => {
    setPageLoading(loading);
  };

  const setSelectedChatIdHandler = (id?: number) => {
    setSelectedChatId(id);
  };

  const clearSelectedChatIdHandler = () => {
    setSelectedChatIdHandler();
  };

  const setChatNameHandler = (chatId: number, newName: string) => {
    setChats((prev) =>
      prev?.map((chat) => {
        return chat.id === chatId ? { ...chat, name: newName } : chat;
      })
    );
  };

  const navigateToWelcome = () => {
    clearAuthenticationCode();
    navigate("/welcome");
    setPageLoading(false);
  };

  const navigateToChat = (id: number) => {
    navigate(`/chat/${id}`);
  };

  const createChatHandler = async (message?: string) => {
    setPageLoading(true);
    try {
      const now = new Date().toISOString();
      const { data } = await createLLMChatsReq({
        code: authCode,
        createdAt: now,
        message,
      });
      setChats((prev) => (prev ? [data, ...prev] : [data]));
      navigateToChat(data.id);
    } catch (error) {
      console.log(error);
      const axiosError = error as AxiosError<{ message: string }>;
      const errorMessage =
        axiosError.response?.data.message || "Error creating chat!";
      const errorStatus = axiosError.response?.status;
      console.log(errorMessage);
      toastError(errorMessage);
      if (errorStatus === 401) {
        navigateToWelcome();
      }
    }

    setPageLoading(false);
  };

  const createChatWithMessageHandler = (message: string) => {
    createChatHandler(message);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
      <Routes>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/auth/" element={<Authentication />} />
        <Route
          path="/chat/:id"
          element={
            <Layout
              chats={chats ?? []}
              showLoading={pageLoading}
              selectedChatId={selectedChatId}
              createChatHandler={createChatHandler}
              navigateToWelcome={navigateToWelcome}
              setChatsHandler={setChatsHandler}
              setPageLoadingHandler={setPageLoadingHandler}
            >
              <ChatPage
                setPageLoadingHandler={setPageLoadingHandler}
                setSelectedChatIdHandler={setSelectedChatIdHandler}
                setChatNameHandler={setChatNameHandler}
                disabled={pageLoading}
              />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout
              chats={chats ?? []}
              showLoading={pageLoading}
              selectedChatId={selectedChatId}
              createChatHandler={createChatHandler}
              navigateToWelcome={navigateToWelcome}
              setChatsHandler={setChatsHandler}
              setPageLoadingHandler={setPageLoadingHandler}
            >
              <HomePage
                clearSelectedChatIdHandler={clearSelectedChatIdHandler}
                disabled={pageLoading}
                createChatWithMessageHandler={createChatWithMessageHandler}
              />
            </Layout>
          }
        />
        {/* i need a route of * to redirect to "/" */}
        <Route path="*" element={<RedirectToHomePage />} />
        {/* Add more routes here if needed */}
      </Routes>
    </>
  );
};
