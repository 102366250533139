import { useEffect, useRef } from "react";
import {
  LLMChatMessage,
  LLMChatMessageRole,
} from "../../../types/LLMChatMessage";
import styles from "./index.module.css";
import { PulseLoader } from "react-spinners";
import { PromptInput } from "../../../components/common/PromptInput";
import { Message } from "./Message";

type Props = {
  chatId: number;
  messages: LLMChatMessage[];
  responseLoading: boolean;
  disabled: boolean;
  sendMessageHandler: (input: LLMChatMessage) => void;
};

export const MessagesSection = ({
  chatId,
  messages,
  responseLoading,
  disabled,
  sendMessageHandler,
}: Props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const debugMode = localStorage.getItem("debug-mode");

  const disabledMessageButton = disabled || responseLoading;

  const sendMessage = (message: string) => {
    const newMessage: LLMChatMessage = {
      id: 0.1,
      content: message,
      contextCount: 0,
      createdAt: new Date(),
      duration: 0,
      role: LLMChatMessageRole.USER,
    };

    sendMessageHandler(newMessage);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className={styles.chatContainer}>
      <div className={styles.messagesContainer}>
        {messages.map((message) => (
          <Message message={message} debugMode={debugMode === "1"} />
        ))}
        {responseLoading && (
          <div className={styles.loadingMessage}>
            <PulseLoader color="#000000" size={5} />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.inputContainer}>
        <PromptInput
          disabled={disabledMessageButton}
          sendMessageHandler={sendMessage}
          chatId={chatId}
        />
      </div>
    </div>
  );
};
