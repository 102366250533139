import Markdown from "react-markdown";
import styles from "./index.module.css";
import remarkGfm from "remark-gfm";

type FormattedMessageProps = {
  text: string;
};

export const FormattedMessage = ({ text }: FormattedMessageProps) => {
  return (
    <div className={styles.formattedMessage}>
      <Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
        {text}
      </Markdown>
    </div>
  );
};
