export const storeAuthenticationCode = (code: string) => {
  localStorage.setItem("auth-code", code);
};

export const getAuthenticationCode = () => {
  return localStorage.getItem("auth-code") ?? "";
};

export const clearAuthenticationCode = () => {
  localStorage.removeItem("auth-code");
};
