import { useState, useEffect } from "react";

import { LLMChatMessage, LLMChatMessageRole } from "../../types/LLMChatMessage";
import { loadLLMChatReq, sendLLMChatMessageReq } from "../../api/requests";
import { toastError } from "../../utils/toastError";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { MessagesSection } from "./MessagesSection";
import {
  clearAuthenticationCode,
  getAuthenticationCode,
} from "../../utils/local-storage";

type Props = {
  disabled: boolean;
  setSelectedChatIdHandler: (id?: number) => void;
  setPageLoadingHandler: (loading: boolean) => void;
  setChatNameHandler: (chatId: number, newName: string) => void;
};

export const ChatPage = ({
  disabled,
  setPageLoadingHandler,
  setSelectedChatIdHandler,
  setChatNameHandler,
}: Props) => {
  const [messages, setMessages] = useState<LLMChatMessage[]>([]);
  const [responseLoading, setResponseLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams(); // Access the "id" parameter from the URL
  const chatId = id ? +id : undefined;

  const navigateToWelcome = () => {
    clearAuthenticationCode();
    navigate("/welcome");
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const loadChatMessages = async (id: number) => {
    setPageLoadingHandler(true);
    try {
      const authCode = getAuthenticationCode();
      const { data } = await loadLLMChatReq(id, { code: authCode });
      setMessages(data.messages);
      setSelectedChatIdHandler(+id);
    } catch (error) {
      const axiosError = error as AxiosError<{ message: string }>;
      const errorMessage =
        axiosError.response?.data.message || "Error loading chat messages!";
      toastError(errorMessage);
      const errorStatus = axiosError.response?.status;
      if (errorStatus === 401) {
        navigateToWelcome();
      } else {
        navigateToHome();
      }
    }

    setPageLoadingHandler(false);
  };

  const sendMessageHandlerAsync = async (newMessage: LLMChatMessage) => {
    if (!chatId) return;
    const oldMessages = [...messages];
    const firstMessage = !oldMessages.filter(
      ({ role }) => role === LLMChatMessageRole.USER
    ).length;
    setMessages((messages) => [...messages, newMessage]);
    setResponseLoading(true);
    // send axios request
    try {
      const authCode = getAuthenticationCode();
      const { data } = await sendLLMChatMessageReq({
        code: authCode,
        chatId,
        message: newMessage.content,
      });
      setMessages([
        ...oldMessages,
        ...data, // Add the new messages from the data received
      ]);
      if (firstMessage) {
        setChatNameHandler(chatId, newMessage.content);
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ message: string }>;
      const errorMessage =
        axiosError.response?.data.message || "Error sending/receiving message";
      toastError(errorMessage);
      const errorStatus = axiosError.response?.status;
      if (errorStatus === 401) {
        navigateToWelcome();
      }
    }
    setResponseLoading(false);
  };

  const sendMessageHandler = (newMessage: LLMChatMessage) => {
    sendMessageHandlerAsync(newMessage);
  };

  const filteredMessages = messages.filter(
    ({ role }) => role !== LLMChatMessageRole.SYSTEM
  );

  useEffect(() => {
    const storedCode = getAuthenticationCode();
    if (!storedCode) {
      navigateToWelcome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    setResponseLoading(false);
    if (chatId) {
      loadChatMessages(chatId);
    } else {
      navigateToHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <MessagesSection
      messages={filteredMessages}
      disabled={disabled}
      responseLoading={responseLoading}
      sendMessageHandler={sendMessageHandler}
      chatId={chatId ?? 0}
    />
  );
};
