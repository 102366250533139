import {
  LLMChatMessage,
  LLMChatMessageRole,
} from "../../../../types/LLMChatMessage";
import { FormattedMessage } from "./FormattedMessage";
import styles from "./index.module.css";

type Props = {
  message: LLMChatMessage;
  debugMode: boolean;
};

export const Message = ({ message, debugMode }: Props) => {
  const isAssitant = message.role === LLMChatMessageRole.ASSISTANT;
  const isUser = message.role === LLMChatMessageRole.USER;
  const messageAlignment = isUser
    ? styles.userAlignment
    : isAssitant
    ? styles.assistantAlignment
    : styles.systemAlignment;

  const imageSrc = isUser
    ? "/assets/default-user-icon.png"
    : isAssitant
    ? "/assets/logo-icon.png"
    : "/assets/logo-icon.png";

  const messageStyle = isUser
    ? styles.userMessage
    : isAssitant
    ? styles.assistantMessage
    : styles.systemMessage;

  return (
    <div className={`${styles.messageContainer} ${messageAlignment}`}>
      <div className={styles.imageContainer}>
        <img src={imageSrc} alt="default user icon" height="35px" />
      </div>

      <div key={message.id} className={`${styles.message} ${messageStyle}`}>
        {debugMode && isAssitant && (
          <div className={styles.debuggingData}>
            {!!message.sqlQuery && <span>SQL: {message.sqlQuery}</span>}
            {!!message.contextCount && (
              <span>Tokens: {message.contextCount}</span>
            )}
          </div>
        )}
        <FormattedMessage text={message.content} />
      </div>
    </div>
  );
};
