export enum LLMChatMessageRole {
  SYSTEM = "system",
  ASSISTANT = "assistant",
  USER = "user",
}

export type LLMChatMessage = {
  id: number;
  createdAt: Date;
  content: string;
  role: LLMChatMessageRole;
  contextCount: number;
  duration: number;
  sqlQuery?: string;
  chatId?: number;
};
