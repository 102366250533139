import { useEffect, useState } from "react";
import styles from "./index.module.css";

type Props = {
  disabled: boolean;
  sendMessageHandler: (message: string) => void;
  chatId?: number;
};

export const PromptInput = ({
  disabled,
  sendMessageHandler,
  chatId,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  const trimmedInputValue = inputValue.trim();
  const disabledButton = !trimmedInputValue || disabled;

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const sendMessage = () => {
    if (disabledButton) return;

    sendMessageHandler(inputValue);
    setInputValue("");
  };

  useEffect(() => {
    setInputValue("");
  }, [chatId]);

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        placeholder="Skicka ett meddelande till Clouseau"
        value={inputValue}
        onChange={inputChangeHandler}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sendMessage();
          }
        }}
      />
      <div
        className={`${styles.iconContainer} ${
          disabledButton ? styles.disabled : ""
        }`}
        onClick={sendMessage}
      >
        <img
          src="/assets/logo-icon.png"
          alt="clouseau logo icon"
          height="100%"
        />
      </div>
    </div>
  );
};
