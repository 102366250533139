import { LLMChat } from "../types/LLMChat";

type GroupedByDateChats = {
  today: LLMChat[];
  yesterday: LLMChat[];
  lastWeek: LLMChat[];
  history: LLMChat[];
};

export const groupChatsByDate = (chats: LLMChat[]): GroupedByDateChats => {
  const today = new Date();
  const result: GroupedByDateChats = {
    today: [],
    yesterday: [],
    lastWeek: [],
    history: [],
  };

  for (let i = 0; i < chats.length; i++) {
    const chat = chats[i];
    const chatDate = new Date(chat.createdAt);
    const timeDiff = today.getTime() - chatDate.getTime();
    const dayDiff = timeDiff / (1000 * 60 * 60 * 24);

    if (dayDiff < 1 && today.getDate() === chatDate.getDate()) {
      result.today.push(chat);
    } else if (
      dayDiff < 2 &&
      (today.getDate() - chatDate.getDate() === 1 ||
        (today.getDate() === 1 &&
          chatDate.getDate() ===
            new Date(today.getFullYear(), today.getMonth(), 0).getDate()))
    ) {
      result.yesterday.push(chat);
    } else if (dayDiff <= 7) {
      result.lastWeek.push(chat);
    } else {
      result.history.push(chat);
    }
  }

  return result;
};
