import { PlusIcon } from "../../../../icons/plus-icon";
import styles from "./index.module.css";

type Props = {
  createChatHandler: () => void;
};

export const CreateChatButton = ({ createChatHandler }: Props) => {
  return (
    <div className={styles.button} onClick={() => createChatHandler()}>
      <PlusIcon />
      <span>Ny chatt</span>
    </div>
  );
};
