import axios from "axios";
import { envVars } from "../utils/env-vars";

const axiosClient = axios.create({
  baseURL: envVars.REACT_APP_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// axiosClient.interceptors.request.use((request) => {
//   const authCode = getAuthenticationCode();
//   console.log(authCode);
//   if (authCode) {
//     request.headers["Code"] = authCode;
//   }
//   return request;
// });

export default axiosClient;
