export const UserIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="28px"
      height="28px"
      viewBox="0 0 32.000000 32.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M120 260 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 23 -23 25 -30
7 -30 -18 0 -53 -39 -62 -67 -10 -34 8 -27 25 10 18 38 58 55 98 41 15 -5 33
-23 42 -41 17 -37 35 -44 25 -10 -9 28 -44 67 -62 67 -18 0 -16 7 7 30 25 25
25 55 0 80 -11 11 -29 20 -40 20 -11 0 -29 -9 -40 -20z m70 -15 c26 -32 -13
-81 -47 -59 -23 14 -28 41 -13 59 16 19 44 19 60 0z"
        />
      </g>
    </svg>
  );
};
