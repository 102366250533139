import { BrowserRouter } from "react-router-dom";
import { IndexPage } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <IndexPage />
    </BrowserRouter>
  );
}

export default App;
