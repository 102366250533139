import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { UserIcon } from "../../../icons/user-icon";

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.imageContainer}>
        <Link to="/">
          <img src="/assets/logo.png" height="40px" alt="clouseau logo" />
        </Link>
      </div>
      <div className={styles.linksContainer}>
        <Link to="#">Om</Link>
        <Link to="#">Kontakta</Link>
        <Link to="#">
          <UserIcon />
        </Link>
      </div>
    </header>
  );
};
