import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import {
  getAuthenticationCode,
  storeAuthenticationCode,
} from "../../utils/local-storage";
import { toastError } from "../../utils/toastError";
import { AuthenticateLLMChatInput } from "../../types/authenticate-chat-input";
import { authenticateLLMChatsReq } from "../../api/requests";
import { AxiosError } from "axios";

export const Authentication = () => {
  const [authenticationCode, setAuthenticationCode] = useState("");

  const navigate = useNavigate();

  const submitHandler = async (input: AuthenticateLLMChatInput) => {
    try {
      await authenticateLLMChatsReq(input);
      storeAuthenticationCode(authenticationCode);
      navigate("/");
    } catch (error) {
      const axiosError = error as AxiosError<{ message: string }>;
      const errorMessage =
        axiosError.response?.data.message || "Invalid/Expired code";
      toastError(errorMessage);
    }
  };

  const handleAuthenticationSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (authenticationCode.trim() !== "") {
      submitHandler({ code: authenticationCode.trim() });
    } else {
      toastError("Invalid input!");
    }
  };

  useEffect(() => {
    const storedCode = getAuthenticationCode();
    if (storedCode) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className={styles.page}>
      <form onSubmit={handleAuthenticationSubmit}>
        <div className={styles.inputContainer}>
          <label htmlFor="authenticationCode">Authentication Code:</label>
          <input
            type="password"
            id="authenticationCode"
            value={authenticationCode}
            onChange={(e) => setAuthenticationCode(e.target.value)}
            placeholder="Enter authentication code..."
            className={styles.inputField}
          />
        </div>
        <button
          type="submit"
          className={styles.submitButton}
          disabled={!authenticationCode}
        >
          Submit
        </button>
      </form>
    </div>
  );
};
